<template>
  <section class="container mt-3">
    <edit-company v-model="company" class="mt-3"></edit-company>
    <edit-company-info v-model="company"></edit-company-info>
    <a-row type="flex" justify="end">
      <a-button
        @click="editCompany"
        class="bg-warning text-white ml-2 mb-5 "
        size="large"
      >
        O'zgartirish</a-button
      >
    </a-row>

    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos barcha malomotlarni kiriting ):
      </p>
    </modal>
  </section>
</template>

<script>
import EditCompany from '../../components/Company/EditCompany.vue';
import EditCompanyInfo from '../../components/Company/EditCompanyInfo.vue';
import request, { formDataRequest } from '../../api/fetchConfig';
export default {
  components: {
    EditCompany,
    EditCompanyInfo,
  },

  data() {
    return {
      inValid: false,
      company: {
        thumbnail: '',
        video_url: '',
        title: '',
        title_en: '',
        title_ru: '',
        title_uz: '',
        content: '',
        content_en: '',
        content_ru: '',
        content_uz: '',
        image: '',
      },
    };
  },

  methods: {
    closeModal(bool) {
      this.inValid = bool;
    },

    editCompany() {
      for (let i in this.company) {
        if (this.company[i] === '') {
          this.inValid = true;
          return;
        }
      }
      if (!this.company.thumbnail.obj) {
        delete this.company.thumbnail;
      } else {
        this.company.thumbnail = this.company.thumbnail.files;
      }
      if (!this.company.image.obj) {
        delete this.company.image;
      } else {
        this.company.image = this.company.image.files;
      }
      this.patchCompany(this.company);
    },

    patchCompany(data) {
      formDataRequest('contact/aboutus/3/', 'PATCH', data)
        .then((res) => {
          this.$notification['success']({
            message: 'Success',
            description: 'Serverda Company ozgartirildi ):',
          });
          this.company = res;
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
        });
    },
  },
  created() {
    request('contact/aboutus/3/', 'GET').then((res) => {
      this.company = res;
    });
  },
};
</script>
