<template>
  <section>
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }">
      <a-form-item label="Kompaniya haqida">
        <a-input
          type="textarea"
          rows="6"
          v-model="value.content"
          :value="value.content"
        />
      </a-form-item>
      <a-form-item label="Kompaniya haqida [en]">
        <a-input
          type="textarea"
          rows="6"
          v-model="value.content_en"
          :value="value.content_en"
        />
      </a-form-item>
      <a-form-item label="Kompaniya haqida [ru]">
        <a-input
          type="textarea"
          rows="6"
          v-model="value.content_ru"
          :value="value.content_ru"
        />
      </a-form-item>
      <a-form-item label="Kompaniya haqida [uz]">
        <a-input
          type="textarea"
          rows="6"
          v-model="value.content_uz"
          :value="value.content_uz"
        />
      </a-form-item>
      <a-form-item label="Rasm">
        <a-input type="file" @change="imgUpload" />
        <img
          :src="cPreviewImg"
          :alt="oldImg"
          width="100"
          height="100"
          class="mt-3"
        />
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
export default {
  props: {
    value: Object,
    default: () => ({}),
  },
  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      oldImg: '',
    };
  },
  computed: {
    cPreviewImg() {
      return this.oldImg
        ? this.previewImg
          ? URL.createObjectURL(this.previewImg)
          : this.oldImg
        : this.oldImg;
    },
  },
  methods: {
    imgUpload(e) {
      const files = e.target.files[0];
      this.oldImg = files;
      this.previewImg = files;
      const obj = {
        files,
        obj: true,
      };
      this.value.image = obj;
      this.$notification['success']({
        message: 'Success',
        description: 'Rasm qoshildi ):',
      });
    },
  },
  updated() {
    if (!this.oldImg) {
      this.oldImg = this.value.image;
    }
  },
};
</script>

<style scoped>
input[type='file'] {
  cursor: pointer;
  width: 40%;
  height: 37px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
}
input[type='file']:focus {
  outline: none;
}

input[type='file']:before {
  width: 50%;
  font-size: 15px;
  line-height: 27px;

  content: 'Rasm Yuklash';
  display: inline-block;
  background: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
